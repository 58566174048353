import theLine from "../../assets/images/theLine.svg";
import DividerMob1 from "../../assets/images/mobile/DividerMob1.svg";
const SeparatedLine = () => {
  return (
    <>
      <div className="w-full md:block xs:hidden">
        <img src={theLine} alt="Separated Line" />
      </div>
      <div className="w-full md:hidden xs:block">
        <img className="w-full" src={DividerMob1} alt="Separated Line" />
      </div>
    </>
  );
};
export default SeparatedLine;
