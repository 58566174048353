import "./App.css";
import { BrowserRouter } from "react-router-dom";
import NavBar from "./components/NavBar";

import { useEffect, useState } from "react";
import isAuthenticated from "./utils/isAuthenticated";
import RenderRoutes from "./components/Routes/RenderRoutes";
import phantasmLogo from "./assets/images/phantasmLogo.png";
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState(null);
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);

  const onAutChange = () => {
    if (localStorage.userType) {
      setIsLoggedIn(isAuthenticated());
      setUserType(localStorage.userType);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      if (window.scrollY > window.innerHeight) {
        setShowBackToTopButton(true);
      } else {
        setShowBackToTopButton(false);
      }
    });
    onAutChange();
  }, []);
  return (
    <BrowserRouter>
      <header>
        <NavBar isAuthenticated={isLoggedIn} />
      </header>
      <main className="">
        <RenderRoutes userType={userType} onAutChange={onAutChange} />
      </main>
      <footer
        className={`ease-in duration-300 bg-primary w-full min-h-[130px] flex flex-wrap items-center px-10 py-5 ${
          isLoggedIn && showBackToTopButton
            ? "justify-between"
            : "justify-center"
        }  text-white`}
      >
        <div className="text-xl uppercase ease-in duration-300 md:flex xs:hidden">
          Yassin and Natasha
        </div>
        {isLoggedIn && showBackToTopButton && (
          <div className="ease-in duration-300 text-center md:w-auto xs:w-full  text-center">
            <button
              className="uppercase"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              back to top
            </button>
          </div>
        )}

        {isLoggedIn && !showBackToTopButton && (
          <div className="uppercase ease-in duration-300 text-center md:hidden md:w-auto xs:w-full text-center">
            Yassin and Natasha
          </div>
        )}

        <div className="w-full text-end uppercase text-sm mt-2 flex flex-wrap items-center md:justify-end xs:justify-center md:my-0 xs:my-5">
          powered by{" "}
          <a
            href="https://phantasm.biz"
            target="_blank"
            className="border border-[#690000] mx-7 p-2 px-2 flex  items-center phantasm-logo md:my-0 xs:my-2"
          >
            {" "}
            <img className="w-6" src={phantasmLogo} alt="" />
            <span className="ms-2 text-lg text-[#690000]">phantasm</span>
          </a>{" "}
          © 2024 | All rights reserved
        </div>
      </footer>
    </BrowserRouter>
  );
}

export default App;
