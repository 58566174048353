import { Outlet, Navigate } from "react-router-dom";
import isAuthenticated from "../utils/isAuthenticated";

const ProtectedRoutes = ({ element: Element, ...rest }) => {
  return isAuthenticated() ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: "/login",
      }}
    />
  );
};

export default ProtectedRoutes;
