import SecondLine from "./Global/SecondLine";
import SeparatedLine from "./Global/SeparatedLine";
import faq1 from "../assets/images/faq1.svg";
import faq2 from "../assets/images/faq2.svg";
import faq3 from "../assets/images/faq3.svg";
import faq4 from "../assets/images/mobile/faq4.svg";
import AnimatedPages from "./Global/Animation";
const Faq = () => {
  return (
    <AnimatedPages>
      <div className="uppercase">
        {" "}
        <div className="flex flex-wrap justify-center items-start">
          <div className="w-full text-center flex flex-wrap justify-center items-center my-20">
            <div className="md:w-2/12 md:flex xs:hidden justify-end">
              <img className="w-[60%]" src={faq1} alt="faq1" />
            </div>
            <div className="md:text-xl xs:text-lg flex flex-wrap justify-center tracking-[5px] text-primary font-semibold px-5">
              <div className="md:hidden w-full xs:flex justify-center mb-8">
                <img className="w-32" src={faq4} alt="faq4" />
              </div>
              <p className="md:text-lg xs:text-[16px]">
                {" "}
                Important info <br /> and faq
                <span className="lowercase">s</span>
              </p>
            </div>
            <div className="md:w-2/12 md:flex xs:hidden">
              <img className="w-[60%]" src={faq2} alt="faq2" />
            </div>
          </div>

          <div className="w-full">
            <SeparatedLine />
          </div>
        </div>
        <div className="flex flex-wrap justify-center items-start">
          <div className="w-full text-center flex flex-wrap justify-center items-center my-24">
            <div className="md:text-xl xs:text-[13px] tracking-[0.06px] leading-6 lg:w-6/12 md:w-7/12 flex justify-center text-primary flex-wrap px-5">
              <h6 className="w-full font-[200] ">
                What do I wear on the wedding day?
              </h6>
              <p className="w-full mt-5 ">
                Men in black suits.
                <br />
                women in maxi dresses.
              </p>

              <h6 className="w-full font-[200] mt-20">
                What will the weather be like?
              </h6>
              <p className="w-full mt-5 ">
                During the day you can expect it to <br />
                be hot of a temperature around <br />
                25-30 degrees and slightly chillier at <br />
                night. You may even be able to tan, <br />
                so bring a swimsuit if you’d like to. <br />
              </p>

              <h6 className="w-full font-[200] mt-20">How do I get around?</h6>
              <p className="w-[95%] mt-5 ">
                The hotel and concierge desks <br />
                can arrange transportation for <br />
                you where you need it <br />
                throughout your stay.
              </p>
              <p className="mt-6">
                On the wedding day, we will have <br />
                gondolas that will be taking <br />
                guests from the Movenpick to the <br />
                Cataract and back. The city is <br />
                made up of islands, so you will <br />
                also be able to travel by river taxi <br />/ gondolas. Please
                have some cash <br />
                at your disposal. if you need to <br />
                exchange cash, the hotel can do <br />
                this for you.
              </p>

              <h6 className="w-full font-[200] mt-20">HOW DO I RSVP?</h6>
              <p className="w-full mt-5 underline">
                <a
                  rel="noreferrer"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSc_SrarLN-lD8_M71aZ63hGA1IRFpfTH1zlxQrenlZ7X2n9Sg/viewform"
                  target="_blank"
                >
                  click here to rsvp
                </a>
              </p>

              <h6 className="w-full font-[200] mt-20">
                WHEN IS THE DEADLINE TO BOOK A ROOM?
              </h6>
              <p className="w-full mt-5">
                Kindly book your accomodation by <br />
                JUNE 1ST through Martine and/or <br />
                Heba (yasnat@abercrombiekent.com.eg <br />
              </p>
              <p className="mt-3">
                If you don’t book rooms by this date, <br />
                any rooms that are on hold for our <br />
                wedding will be released.
              </p>
              <h6 className="w-full font-[200] mt-20">
                Can I book hair and make-up?
              </h6>
              <p className="w-full mt-5 ">
                We will have a salon set up at the <br />
                hotel which you can book an appointment <br />
                with. We will update this section with <br />
                contact details for bookings <br />
                closer to the time so keep an eye out.
              </p>

              <h6 className="w-full font-[200] mt-20">what to pack?</h6>
              <div className="w-full mt-5  flex justify-center">
                <ul>
                  <li>
                    Women can bring flats with them as sometimes <br />
                    you'll be hopping on and off gondolas.
                  </li>
                  <li className="mt-5">
                    a sun hat if you want to protect yourself <br />
                    from the sun.
                  </li>
                  <li className="mt-5">
                    a shawl in case you get chilly at night.
                  </li>
                  <li className="mt-5">a swimsuit.</li>
                  <li className="mt-5">sunglasses.</li>
                  <li className="mt-5">spf.</li>
                </ul>
              </div>

              <h6 className="w-full font-[200] mt-20">
                What if i need more information?
              </h6>
              <p className="w-full mt-5 ">
                There will be an information desk at <br />
                the Cataract hotel should you need <br />
                any information about the wedding, <br />
                activities or getting around once <br />
                you are in Aswan.
              </p>

              <p className="w-full mt-5">
                check the website again closer to the <br />
                wedding as we will add more information
              </p>

              <div className=" mt-24">
                <img className="md:w-52" src={faq3} alt="faq3" />
              </div>
            </div>
          </div>

          <div className="w-full">
            <SecondLine />
          </div>
        </div>
      </div>
    </AnimatedPages>
  );
};
export default Faq;
