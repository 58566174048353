import { Route, Routes, useLocation } from "react-router-dom";
import ProtectedRoutes from "../ProtectedRoutes";
import Home from "../Home";
import Schedule from "../Schedule";
import Travel from "../Travel";
import Faq from "../Faq";
import Registry from "../Registry";
import Login from "../Login";
import FlipBook from "../FlipBook";

const RenderRoutes = ({ userType, onAutChange }) => {
  const location = useLocation();

  return (
    <Routes location={location} key={location.pathname}>
      <Route element={<ProtectedRoutes />}>
        <Route path="/" element={<Home />} />

        <Route path="schedule" element={<Schedule userType={userType} />} />
        <Route path="travel" element={<Travel />} />
        <Route path="faq" element={<Faq />} />
        <Route path="registry" element={<Registry />} />
        <Route path="guest-book" element={<FlipBook userType={userType} />} />
      </Route>
      <Route path="/login" element={<Login onAutChange={onAutChange} />} />
    </Routes>
  );
};
export default RenderRoutes;
