import SeparatedLine from "./Global/SeparatedLine";
import schedule1 from "../assets/images/schedule1.svg";
import schedule2 from "../assets/images/schedule2.svg";
import SecondLine from "./Global/SecondLine";
import AnimatedPages from "./Global/Animation";

const Schedule = ({ userType }) => {
  const renderBasedOnTheUserType = () => {
    if (
      (userType && userType === "veryImportantPerson") ||
      (userType && userType === "veryveryImportantPerson")
    ) {
      return (
        <>
          {/* first */}
          <div className="flex flex-wrap justify-center items-start">
            <div className="w-full text-center flex flex-wrap justify-center items-center my-24 px-5">
              <div className="tracking-[1px] lg:w-6/12 md:w-8/12 md:text-xl xs:text-[14px] flex justify-center text-primary font-semibold flex-wrap">
                <h6 className="w-full font-thin ">Dinner at the temple</h6>
                <h3 className="w-full mt-3 md:text-2xl xs:text-[15px]">
                  Thursday 17th October
                </h3>
                <p className="mt-2 font-[400] w-full md:text-lg xs:text-[13.25px] leading-6">
                  Philae Temple
                </p>
                <p className="w-full mt-8 font-[400] md:text-lg xs:text-[14px] leading-7">
                  pre-wedding dinner at 7pm
                  <br />
                  Elegance & Glamour dress code &
                  <br />
                  Black Tie
                </p>
              </div>
            </div>

            <div className="w-full">
              <SecondLine />
            </div>
          </div>
          {/* second */}
          <div className="flex flex-wrap justify-center items-start">
            <div className="w-full text-center flex flex-wrap justify-center items-center my-24 px-5">
              <div className="tracking-[.10px] lg:w-6/12 md:w-8/12 md:text-xl xs:text-[14px] flex justify-center text-primary font-semibold flex-wrap">
                <h6 className="w-full font-thin">Wedding on the nile</h6>
                <h3 className="w-full mt-3 md:text-2xl xs:text-[15px]">
                  Friday 18th OCTOBER
                </h3>
                <p className="mt-2 font-[400] w-full md:text-lg xs:text-[13.25px] leading-6">
                  OLD CATARACT HOTEL
                </p>{" "}
                <p className="w-full mt-8 font-[400] md:text-lg xs:text-[13.25px] leading-6">
                  We will start the day with the
                  <br />
                  Ceremony at 3pm followed by the
                  <br />
                  wedding which will take place <br />
                  immediately after, from sunset <br />
                  into the night.
                </p>
                <p className="w-full mt-8 font-[400] md:text-lg xs:text-[13.25px] leading-6">
                  Men in black suits, women in maxi dresses
                </p>
              </div>
            </div>

            <div className="w-full">
              <SeparatedLine />
            </div>
          </div>
          {/* third */}
          <div className="flex flex-wrap justify-center items-start">
            <div className="w-full text-center flex flex-wrap justify-center items-center my-24 px-5">
              <div className="tracking-[.5px] lg:w-6/12 md:w-8/12 md:text-xl xs:text-[14px] flex justify-center text-primary font-semibold flex-wrap">
                <h6 className="w-full font-thin">Party on the island</h6>
                <h3 className="w-full mt-3 md:text-2xl xs:text-[15px]">
                  Saturday 19th OCTOBER
                </h3>
                <p className="mt-2 font-[400] w-full md:text-lg xs:text-[13.25px] leading-6">
                  Private Island
                </p>{" "}
                <p className="w-full mt-8 font-[400]  md:text-lg xs:text-[13px] leading-6">
                  The party will start before sunset
                  <br /> and continue on into the night. <br />
                  Feel free to wear whatever your
                  <br /> heart desires but most importantly,
                  <br />
                  dress in extravagant colours!
                  <br />
                  We advise not to wear heels <br /> to this event.
                </p>
                <p className="w-full mt-8 font-[400]  md:text-lg xs:text-[13px] leading-6">
                  See moodboard{" "}
                  <a
                    rel="noreferrer"
                    href="https://www.pinterest.co.uk/natashacsenno/after-party-inspo/"
                    target="_blank"
                    className="underline"
                  >
                    here
                  </a>
                </p>
              </div>
            </div>

            <div className="w-full">
              <SecondLine />
            </div>
          </div>
        </>
      );
    } else if (userType && userType === "importantPerson") {
      return (
        <>
          {/* first */}
          <div className="flex flex-wrap justify-center items-start">
            <div className="w-full text-center flex flex-wrap justify-center items-center my-24 px-5">
              <div className="tracking-[1px] lg:w-6/12 md:w-8/12 md:text-xl xs:text-[14px] flex justify-center text-primary font-semibold flex-wrap">
                <h6 className="w-full font-thin ">Dinner at the temple</h6>
                <h3 className="w-full mt-3 md:text-2xl xs:text-[15px]">
                  Thursday 17th October
                </h3>
                <p className="mt-2 font-[400] w-full md:text-lg xs:text-[13.25px] leading-6">
                  Philae Temple
                </p>

                <p className="w-full mt-8 font-[400] md:text-lg xs:text-[14px] leading-7">
                  pre-wedding dinner at 7pm <br />
                  Elegance & Glamour dress code &
                  <br />
                  Black Tie
                </p>
              </div>
            </div>

            <div className="w-full">
              <SecondLine />
            </div>
          </div>
          {/* second */}
          <div className="flex flex-wrap justify-center items-start">
            <div className="w-full text-center flex flex-wrap justify-center items-center my-24 px-5">
              <div className="tracking-[.10px] lg:w-6/12 md:w-8/12 md:text-xl xs:text-[14px] flex justify-center text-primary font-semibold flex-wrap">
                <h6 className="w-full font-thin">Wedding on the nile</h6>
                <h3 className="w-full mt-3 md:text-2xl xs:text-[15px]">
                  Friday 18th OCTOBER
                </h3>
                <p className="mt-2 font-[400] w-full md:text-lg xs:text-[13.25px] leading-6">
                  OLD CATARACT HOTEL
                </p>
                <p className="w-full mt-8 font-[400] md:text-lg xs:text-[13.25px] leading-6">
                  We will start the day with the
                  <br />
                  Ceremony at 3pm followed by the
                  <br />
                  wedding which will take place <br />
                  immediately after, from sunset <br />
                  into the night.
                </p>
                <p className="w-full mt-8 font-[400] md:text-lg xs:text-[13.25px] leading-6">
                  Men in black suits, women in maxi dresses
                </p>
              </div>
            </div>

            <div className="w-full">
              <SeparatedLine />
            </div>
          </div>
        </>
      );
    } else if (userType && userType === "notNormalPerson") {
      return (
        <>
          {/* second */}
          <div className="flex flex-wrap justify-center items-start">
            <div className="w-full text-center flex flex-wrap justify-center items-center my-24 px-5">
              <div className="tracking-[.10px] lg:w-6/12 md:w-8/12 md:text-xl xs:text-[14px] flex justify-center text-primary font-semibold flex-wrap">
                <h6 className="w-full font-thin">Wedding on the nile</h6>
                <h3 className="w-full mt-3 md:text-2xl xs:text-[15px]">
                  Friday 18th OCTOBER
                </h3>
                <p className="mt-2 font-[400] w-full md:text-lg xs:text-[13.25px] leading-6">
                  OLD CATARACT HOTEL
                </p>
                <p className="w-full mt-8 font-[400] md:text-lg xs:text-[13.25px] leading-6">
                  We will start the day with the
                  <br />
                  Ceremony at 3pm followed by the
                  <br />
                  wedding which will take place <br />
                  immediately after, from sunset <br />
                  into the night.
                </p>
                <p className="w-full mt-8 font-[400] md:text-lg xs:text-[13.25px] leading-6">
                  Men in black suits, women in maxi dresses
                </p>
              </div>
            </div>

            <div className="w-full">
              <SeparatedLine />
            </div>
          </div>
          {/* third */}
          <div className="flex flex-wrap justify-center items-start">
            <div className="w-full text-center flex flex-wrap justify-center items-center my-24 px-5">
              <div className="tracking-[.5px] lg:w-6/12 md:w-8/12 md:text-xl xs:text-[14px] flex justify-center text-primary font-semibold flex-wrap">
                <h6 className="w-full font-thin">Party on the island</h6>
                <h3 className="w-full mt-3 md:text-2xl xs:text-[15px]">
                  Saturday 19th OCTOBER
                </h3>
                <p className="mt-2 font-[400] w-full md:text-lg xs:text-[13.25px] leading-6">
                  Private Island
                </p>{" "}
                <p className="w-full mt-8 font-[400]  md:text-lg xs:text-[13px] leading-6">
                  The party will start before sunset
                  <br /> and continue on into the night. <br />
                  Feel free to wear whatever your
                  <br /> heart desires but most importantly,
                  <br />
                  dress in extravagant colours!
                  <br />
                  We advise not to wear heels <br /> to this event.
                </p>
                <p className="w-full mt-8 font-[400]  md:text-lg xs:text-[13px] leading-6">
                  See moodboard{" "}
                  <a
                    rel="noreferrer"
                    href="https://www.pinterest.co.uk/natashacsenno/after-party-inspo/"
                    target="_blank"
                    className="underline"
                  >
                    here
                  </a>
                </p>
              </div>
            </div>

            <div className="w-full">
              <SecondLine />
            </div>
          </div>
        </>
      );
    } else if (userType && userType === "normalPerson") {
      return (
        <>
          <div className="flex flex-wrap justify-center items-start">
            <div className="w-full text-center flex flex-wrap justify-center items-center my-24 px-5">
              <div className="tracking-[.25px] lg:w-6/12 md:w-8/12 md:text-xl xs:text-[14px] flex justify-center text-primary font-semibold flex-wrap">
                <h6 className="w-full font-thin">Wedding on the nile</h6>
                <h3 className="w-full mt-3 md:text-2xl xs:text-[15px]">
                  Friday 18th OCTOBER
                </h3>
                <p className="mt-2 font-[400] w-full md:text-lg xs:text-[13.25px] leading-6">
                  OLD CATARACT HOTEL
                </p>

                <p className="mt-8 font-[400] w-full md:text-lg xs:text-[13.25px] leading-6">
                  We will start the day with the <br />
                  Ceremony at 3pm followed <br />
                  by the wedding which will take place <br />
                  immediately after, from sunset <br />
                  into the night.
                </p>
                <p className="w-full mt-8 font-[400] md:text-lg xs:text-[13.25px] leading-6">
                  Men in black suits, women in maxi dresses
                </p>
                <p className="mt-8 font-[400] w-full md:text-lg xs:text-[13.25px] leading-6">
                  We advise everyone arrives on the <br />
                  17th at night, latest 18th early <br />
                  morning, so that you can settle into <br />
                  the hotel before the day kicks off.
                </p>
              </div>
            </div>

            <div className="w-full">
              <SeparatedLine />
            </div>
          </div>
        </>
      );
    } else {
      return "";
    }
  };
  return (
    <AnimatedPages>
      <div className="uppercase">
        <div className="flex flex-wrap justify-center items-start">
          <div className="w-full text-center flex flex-wrap justify-center items-center my-20">
            <div className="md:w-2/12 md:flex xs:hidden justify-end">
              <img className="w-[80%]" src={schedule1} alt="schedule1" />
            </div>
            <div className="md:w-3/12 text-xl flex flex-wrap justify-center tracking-[7px] text-primary font-semibold">
              <div className="w-full md:hidden xs:flex justify-center mb-5">
                <img className="w-36" src={schedule2} alt="schedule1" />
              </div>
              <p>Schedule</p>
            </div>
            <div className="md:w-2/12  md:flex xs:hidden ">
              <img className="w-[80%]" src={schedule2} alt="schedule2" />
            </div>
          </div>

          <div className="w-full">
            <SeparatedLine />
          </div>
        </div>

        {renderBasedOnTheUserType()}
      </div>
    </AnimatedPages>
  );
};
export default Schedule;
