import SeparatedLine from "./Global/SeparatedLine";
import SecondLine from "./Global/SecondLine";
import home1 from "../assets/images/home1.svg";
import home2 from "../assets/images/home2.svg";
import comingSoon1 from "../assets/images/comingSoon1.svg";
import AnimatedPages from "./Global/Animation";
import Countdown from "./Global/Countdown";

const Home = () => {
  return (
    <AnimatedPages>
      <div className="home flex flex-wrap items-between">
        <SeparatedLine />
        <div className="w-full flex items-center p-10 py-12">
          <div className="md:w-3/12 md:flex xs:hidden justify-end">
            <img className="lg:w-[45%]" src={home1} alt="home1" />
          </div>
          <div className="md:w-6/12 xs:w-full lg:px-16 md:px-6 text-center ease-in duration-200 ">
            <div className="w-full md:hidden xs:flex justify-center">
              <img className="w-[45%]" src={comingSoon1} alt="home1" />
            </div>
            <div className="uppercase md:block xs:hidden font-[500] text-primary text-[17px] leading-7">
              <Countdown />
              <p className="mb-4">
                Your presence means the world to us and we can't wait to share
                this awesome day with you!
              </p>
              This website will be your one-stop destination for all things
              related to our wedding. Navigate around the website to get the
              lowdown on all the wedding details. We hope it helps you prepare
              for our big day and allows you to share in the excitement.
            </div>

            <div className="uppercase md:hidden xs:block font-[500] text-primary xs:text-[13px] leading-7">
              <Countdown />
              <p className="mb-4">
                Your presence means the world
                <br />
                to us and we can't wait to share
                <br />
                this awesome day with you!
              </p>
              This website will be your one-
              <br />
              stop destination for all things
              <br />
              related to our wedding. <br /> Navigate around the website to
              <br />
              get the lowdown on all the
              <br />
              wedding details. We hope it
              <br />
              helps you prepare for our big
              <br />
              day and allows you to share in
              <br />
              the excitement.
            </div>
            <p className="uppercase font-[500] text-primary md:text-lg xs:text-[13.25px] mt-12">
              With love, <br /> Yassin & Natasha
            </p>

            <p className="uppercase font-[400] text-primary md:text-lg xs:text-[13.25px] mt-3">
              <a
                rel="noreferrer"
                href="https://docs.google.com/forms/d/e/1FAIpQLSc_SrarLN-lD8_M71aZ63hGA1IRFpfTH1zlxQrenlZ7X2n9Sg/viewform"
                target="_blank"
                className="underline"
              >
                To RSVP click here
              </a>
            </p>
          </div>

          <div className="md:w-3/12 md:flex xs:hidden">
            <img className="lg:w-[45%]" src={home2} alt="home2" />
          </div>
        </div>

        <div className="self-end w-full">
          <SecondLine />
        </div>
      </div>
    </AnimatedPages>
  );
};
export default Home;
