const pagesArray = [
  {
    content: "",
    bg: "#435B34",
    padding: "p-[5px]",
    withBorder: false,
    shadow:
      "5px 5px 10px rgba(0, 0, 0, 0.1),inset 3px 2px 10px 0px rgba(0, 0, 0, 0.1)",
    withForm: false,
    withCoverWord: true,
  },
  {
    content: "",
    bg: "#fff8ee",
    padding: "ps-[5px] pt-[5px] pb-[5px]",
    withBorder: false,
    shadow:
      "5px 5px 10px rgba(0, 0, 0, 0.4),inset 3px 2px 10px 0px rgba(0, 0, 0, 0.4)",
    withForm: false,
    withContent: true,
  },
  {
    content: "",
    bg: "#fff8ee",
    padding: "pe-[5px] pt-[5px] pb-[5px]",
    withBorder: true,
    shadow:
      "5px 5px 10px rgba(0, 0, 0, 0.4),inset 5px 1px 30px rgba(0, 0, 0, 0.4)",
    withForm: true,
  },
  {
    content: "",
    bg: "#435B34",
    padding: "p-[5px]",
    withBorder: false,
    shadow:
      "5px 5px 10px rgba(0, 0, 0, 0.1),inset 3px 2px 10px 0px rgba(0, 0, 0, 0.1)",
    withForm: false,
    withEnd: true,
  },
];
export default pagesArray;
