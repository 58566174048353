import React, { useState, useEffect } from "react";
import "./Countdown.css";

const Countdown = () => {
  const calculateTimeLeft = () => {
    const now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth();
    const day = 18;
    const targetHour = 15; // 3 PM

    // If today is after the 18th or it's the 18th but past 3 PM, set the target to the 18th of the next month
    if (
      now.getDate() > day ||
      (now.getDate() === day && now.getHours() >= targetHour)
    ) {
      month += 1;
      if (month > 11) {
        month = 0;
        year += 1;
      }
    }

    const targetDate = new Date(year, month, day, targetHour, 0, 0);
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      const newTimeLeft = calculateTimeLeft();
      if (Object.keys(newTimeLeft).length === 0) {
        clearTimeout(timer);
      } else {
        setTimeLeft(newTimeLeft);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  return (
    <>
      <div className="w-full my-8 font-bold text-xl">
        The Adventure Begins In…
      </div>
      <div className=" flex justify-center mb-12">
        <div className="flex flex-wrap justify-center w-[122px] items-end">
          <div>
            <div className="w-full text-[13px] mb-3 time-unit">DAYS</div>
            <div className="text-6xl number ">00 </div>
          </div>
          <div className="text-6xl number  ms-4">:</div>
        </div>
        <div className="flex flex-wrap justify-center w-[122px] items-end">
          <div>
            <div className="w-full text-[13px] mb-3 time-unit">HOURS</div>
            <div className="text-6xl number ">00</div>
          </div>
          <div className="text-6xl number  ms-4">:</div>
        </div>
        <div className="flex flex-wrap justify-center w-[95px] items-end">
          <div>
            <div className="w-full text-[13px] mb-3 time-unit">MINUTES</div>
            <div className="text-6xl number ">00</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Countdown;
