import activities1 from "../assets/images/activities1.svg";
import activities2 from "../assets/images/activities2.svg";
import activities3 from "../assets/images/activities3.svg";
import activities4 from "../assets/images/mobile/activities4.svg";
import activities5 from "../assets/images/mobile/activities5.svg";
import AnimatedPages from "./Global/Animation";
import SecondLine from "./Global/SecondLine";
import SeparatedLine from "./Global/SeparatedLine";

const Travel = () => {
  return (
    <AnimatedPages>
      <div className="uppercase">
        <div className="flex flex-wrap justify-center items-start">
          <div className="w-full text-center flex flex-wrap justify-center items-center p-5 py-20">
            <div className="md:w-2/12 md:flex xs:hidden justify-end">
              <img className="w-[60%]" src={activities1} alt="activities1" />
            </div>
            <div className="md:w-5/12 md:text-xl xs:text-lg flex flex-wrap justify-center tracking-[6px] text-primary font-semibold">
              <div className="w-full md:hidden xs:flex justify-center">
                <img className="w-32" src={activities4} alt="activities1" />
              </div>
              <p className="w-full md:text-lg xs:text-[16px]">
                Activities, Travel <br /> And Accomodation
              </p>
            </div>
            <div className="md:w-2/12 md:flex xs:hidden justify">
              <img className="w-[60%]" src={activities2} alt="activities2" />
            </div>
          </div>

          <div className="w-full">
            <SeparatedLine />
          </div>
        </div>

        <div className="flex flex-wrap justify-center items-start">
          <div className="w-full text-center flex flex-wrap justify-center items-center my-20">
            <div className="tracking-[.10px] leading-6 lg:w-6/12 md:w-8/12 md:text-lg xs:text-[13.25px] flex justify-center text-primary flex-wrap font-[400]">
              <p className="mt-8 px-5 w-full">
                the visa application process to <br />
                enter Egypt is simple and can be <br />
                done through{" "}
                <a
                  rel="noreferrer"
                  href="https://visa2egypt.gov.eg/eVisa/Home;jsessionid=FbsqcuDuKRAQsm0dm5Bgpv4ByRExo3CfvpKqJIdB.pp-ui-02?VISTK=PSXN-PM7D-I181-964H-F7QH-KZ9R-17KQ-86JX-3JPM-PWGH-R34N-FIZQ-OPM8-J4NK-YBRQ-C9UL"
                  target="_blank"
                  className="underline underline-offset-4"
                >
                  the e-visa portal
                </a>
              </p>
              <p className="mt-8 px-5 w-full">
                If you hold a Lebanese passport only, you
                <br />
                will need to get a visa from your local <br />
                Embassy / Consulate of Egypt prior to traveling. <br />
                We advise emailing your consulate to get <br />
                all the application information. If you <br />
                have a Lebanese passport and you also <br />
                hold an Emirates Identification Card, you
                <br />
                will be able to get your Visa upon arrival.
              </p>
              <p className="mt-8 px-5 w-full">
                For anyone who is getting a visa upon arrival, <br />
                please be sure to carry $25 on you as they only <br />
                accept cash. You will find the visa desk before <br />
                passport control upon exiting the airport.
              </p>
              <p className="mt-8 px-5 w-full">
                There will be an information desk at <br />
                the hotel to arrange any activities <br />
                that you would like to do.
              </p>
              <p className="mt-8 px-5 w-full">
                If you would like to arrange any <br />
                activties ahead of time, please <br />
                contact Martine & Heba on <br />
                <a
                  href="mailto:YASNAT@ABERCROMBIEKENT.COM.EG"
                  className="underline underline-offset-4"
                >
                  yasnat@abercrombiekent.com.eg
                </a>
              </p>
              <p className="mt-8 px-5 w-full">
                We have rooms on hold for our <br />
                guests in the below hotels
              </p>
            </div>
          </div>

          <div className="w-full">
            <SecondLine />
          </div>
        </div>

        <div className="flex flex-wrap justify-center items-start">
          <div className="w-full text-center flex flex-wrap justify-center items-center my-20">
            <div className="md:w-5/12 md:text-xl xs:lg flex justify-center tracking-[2px] text-primary font-semibold">
              Old cataract hotel
            </div>
            <div className="w-full md:hidden xs:flex justify-center my-12">
              <img src={activities5} alt="activities5" />
            </div>
            <div className="md:w-5/12 md:text-xl xs:lg flex justify-center tracking-[2px] text-primary font-semibold">
              Movenpick Hotel
            </div>
          </div>

          <div className="w-full">
            <SeparatedLine />
          </div>
        </div>

        <div className="flex flex-wrap justify-center items-start">
          <div className="w-full text-center flex flex-wrap justify-center items-center my-20">
            <div className="w-full flex justify-center tracking-[.25px] leading-6 text-primary md:text-lg xs:text-[13.25px]">
              <p className="md:w-7/12 px-5">
                Kindly email Martine & Heba <br />
                to receive special rates, reserve <br />
                your room, arrange your <br />
                transportation to and from <br />
                the hotel (and also book flights <br />
                if you wish)
              </p>
            </div>
            <div className="w-full flex justify-center tracking-[.25px] leading-6 text-primary md:text-lg xs:text-[13.25px]">
              <p className="mt-8 md:w-7/12 px-5">
                On the day of the wedding, as the <br />
                wedding will be taking place at the <br />
                Old Cataract, there will be <br />
                transportation arranged to and <br />
                from the Movenpick Hotel.
              </p>
            </div>

            <div className=" mt-24">
              <img
                className="md:w-52 xs:w-44"
                src={activities3}
                alt="activities3"
              />
            </div>
          </div>

          <div className="w-full">
            <SecondLine />
          </div>
        </div>
      </div>
    </AnimatedPages>
  );
};
export default Travel;
