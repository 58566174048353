import { useEffect, useState } from "react";
import bird from "../assets/images/bird.svg";
import smallLine from "../assets/images/lineSmal.png";
import SeparatedLine from "./Global/SeparatedLine";
import axios from "axios";
const GuestBookCards = () => {
  const [listOfCards, setlListOfCards] = useState([]);
  useEffect(() => {
    axios({
      method: "GET",
      url: "https://api.natasha-yassin.com/api/list-guest-book-messages",
    })
      .then((data) => {
        console.log(data?.data);
        if (data?.data) {
          let dataToSort = data.data.data;
          data.data.data.sort((a, b) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            return dateB - dateA;
          });
          setlListOfCards(dataToSort);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div className="bg-primary min-h-[80vh] mt-20 ">
      <div className="bg-secondary" style={{ background: "#FFF8EE" }}>
        {/* <SecondLine /> */}
        <SeparatedLine />
      </div>
      <div className="flex flex-wrap mx-10 mt-20 pb-20">
        {listOfCards.map((card, index) => (
          <div className="md:w-4/12 my-10" key={index}>
            <div className="px-7">
              <div className="bg-white ">
                <div className="w-full">
                  <img src={smallLine} alt="Separated Line" />
                </div>

                <div className="w-full px-5 pb-10">
                  <div className="w-full my-8">
                    <img className="w-[70px]" src={bird} alt="bird" />
                  </div>

                  <div className="my-8 text-primary text-lg">
                    “{card.message}”
                  </div>
                  <div className="text-primary text-lg font-bold">
                    {card.name}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GuestBookCards;
