import { useState } from "react";
import SeparatedLine from "./Global/SeparatedLine";
import loginFirstImg from "../assets/images/loginFirstImg.svg";
import loginSecondImg from "../assets/images/loginSecondImg.svg";
import eyePasswoed from "../assets/images/eye-password.svg";
import loginMob from "../assets/images/mobile/LoginMob.svg";
import arrow from "../assets/images/arrow.svg";
import { useNavigate } from "react-router-dom";
import AnimatedPages from "./Global/Animation";

const Login = ({ onAutChange }) => {
  let navigate = useNavigate();
  const [password, setPassword] = useState(null);
  const [inputType, setInputType] = useState("password");
  const [showErrorEmpty, setShowErrorEmpty] = useState(false);
  const [showErrorWrong, setShowErrorWrong] = useState(false);
  const onPasswordChange = (event) => {
    setPassword(event.target.value);
    setShowErrorEmpty(false);
    setShowErrorWrong(false);
  };
  const onEyeBtnClick = () => {
    if (inputType === "password") setInputType("text");
    else setInputType("password");
  };
  const onLoginSubmit = (event) => {
    event.preventDefault();
    if (!password) {
      setShowErrorEmpty(true);
    } else if (password === "@aLtW83&amZXD)aX") {
      // This can see 17th, 18th and 19th
      localStorage.setItem("userType", "veryveryImportantPerson");
      onAutChange();
      navigate("/");
    } else if (password === "7Ip&9zr!35F;") {
      // This can see 17th, 18th and 19th

      localStorage.setItem("userType", "veryImportantPerson");
      onAutChange();
      navigate("/");
    } else if (password === "&7vT<F3cS77*") {
      // This can see 17th, 18th
      localStorage.setItem("userType", "importantPerson");
      onAutChange();
      navigate("/");
    } else if (password === "CStLwIY4$(x)") {
      // This can see 18th, 19th
      localStorage.setItem("userType", "notNormalPerson");
      onAutChange();
      navigate("/");
    } else if (password === ">j=U9<y2Cs3A") {
      // This can see 18th
      localStorage.setItem("userType", "normalPerson");
      onAutChange();
      navigate("/");
    } else {
      setShowErrorWrong(true);
    }
    //
  };
  return (
    <AnimatedPages>
      <div className="flex flex-wrap items-between login">
        <div className="md:block xs:hidden">
          <SeparatedLine />
        </div>
        <div className="w-full flex items-center my-10">
          <div className="md:w-3/12 md:flex justify-end xs:hidden">
            <img className="w-[60%]" src={loginFirstImg} alt="loginFirstImg" />
          </div>
          <div className="md:w-6/12 md:px-16 xs:w-full xs:px-6 text-center ease-in duration-200">
            <div className="md:w-3/12 md:hidden xs:flex justify-center my-10">
              <img className="w-[150px]" src={loginMob} alt="loginMob" />
            </div>

            <h2 className="uppercase text-primary font-semibold text-2xl tracking-[5px]">
              welcome
            </h2>
            <p className="text-lg mb-8 mt-3 uppercase font-normal] text-primary">
              We’re looking forward to seeing you soon
            </p>
            <form onSubmit={onLoginSubmit} className="ease-in duration-200">
              <div className="w-full relative ease-in duration-200">
                <input
                  name="password"
                  type={inputType}
                  className="w-full px-4 border border-primary h-[52px]  flex items-center justify-center text-center outline-none"
                  placeholder="ENTER YOUR PASSWORD"
                  onChange={onPasswordChange}
                />

                <button
                  type="button"
                  className="absolute top-[50%] -translate-y-[50%] right-2"
                  onClick={onEyeBtnClick}
                >
                  <img className="w-5" src={eyePasswoed} alt="eyePasswoed" />
                </button>
              </div>

              <div className="ease-in duration-200">
                {showErrorEmpty && (
                  <div className="text-red-400 text-sm uppercase mt-1 ease-in duration-200">
                    Please Enter your password
                  </div>
                )}
                {showErrorWrong && (
                  <div className="text-red-400 text-sm uppercase mt-1 ease-in duration-200">
                    Wrong password, please try again!
                  </div>
                )}
              </div>
              <button
                type="submit"
                className="mt-4 w-full border border-primary h-[52px] flex items-center justify-center text-center hover:bg-primary/20 ease-in duration-200"
              >
                <img src={arrow} alt="arrow" />
              </button>
            </form>
          </div>

          <div className="md:w-3/12 md:flex xs:hidden">
            <img
              className="w-[60%]"
              src={loginSecondImg}
              alt="loginSecondImg"
            />
          </div>
        </div>

        <div className="self-end w-full">
          <SeparatedLine />
        </div>
      </div>
    </AnimatedPages>
  );
};

export default Login;
