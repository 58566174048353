import React, { useEffect, useRef, useState } from "react";
import SeparatedLine from "./Global/SeparatedLine";
import SecondLine from "./Global/SecondLine";
import AnimatedPages from "./Global/Animation";

import HTMLFlipBook from "react-pageflip";
import pagesArray from "./pagesArray";
import bookImage from "../assets/images/book.svg";
import arrow from "../assets/images/arrow.svg";
import axios from "axios";
import GuestBookCards from "./GuestBookCards";

const FlipBook = ({ userType }) => {
  const flipBookRef = useRef(null);
  const flipBookRefSmall = useRef(null);

  const [name, setName] = useState("");
  const [Message, setMessage] = useState("");
  const [pagNum, setPagNum] = useState(0);
  const [copyPagNum, setCopyPagNum] = useState(0);

  const [sendedMessage, setSendedMessage] = useState(
    "click here to write a message"
  );

  const handlePageChange = (newPage) => {
    setPagNum(flipBookRef.current.pageFlip().getCurrentPageIndex());
  };

  const handlePageChangeSmall = (newPage) => {
    setPagNum(flipBookRefSmall.current.pageFlip().getCurrentPageIndex());
  };
  const onInit = (e) => {
    console.log(e);
  };

  const nextButtonClick = () => {
    flipBookRef.current.pageFlip().flipNext();
  };
  const prevButtonClick = () => {
    flipBookRef.current.pageFlip().flipPrev();
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    let data = {
      name: name,
      message: Message,
    };
    await axios({
      method: "POST",
      url: "https://api.natasha-yassin.com/api/guest-book",
      headers: {
        Accept: "application/json",
      },
      data: data,
    })
      .then((response) => {
        const largForm = document.querySelector(".normal .theForm");
        if (largForm) {
          document.querySelector(".normal .theForm").style.display = "none";
          document.querySelector(".normal .stf__parent").style.display =
            "block";
          if (copyPagNum == 3) {
            prevButtonClick();
            document
              .querySelector(".wrapper")
              .classList.remove("translate-x-[0px]");
            document
              .querySelector(".wrapper")
              .classList.add("translate-x-[-225px]");
          } else {
            nextButtonClick();

            document
              .querySelector(".wrapper")
              .classList.add("translate-x-[225px]");
          }
        }
        setSendedMessage("The message has been sent.");
        setName("");
        setMessage("");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const onMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const onSmallSubmit = async (event) => {
    event.preventDefault();
    let data = {
      name: name,
      message: Message,
    };
    await axios({
      method: "POST",
      url: "https://api.natasha-yassin.com/api/guest-book",
      headers: {
        Accept: "application/json",
      },
      data: data,
    })
      .then((response) => {
        document.querySelector(".small-wraper .theForm").style.display = "none";
        document.querySelector(".small-wraper .stf__parent").style.display =
          "block";
        flipBookRefSmall.current.pageFlip().flipPrev();
        setSendedMessage("The message has been sent.");
        setName("");
        setMessage("");
        document.getElementById("smallName").value = null;
        document.getElementById("smallMessage").value = null;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderContent = () => {
    if (userType === "veryveryImportantPerson") {
      return <GuestBookCards />;
    } else {
      console.log("from");

      return (
        <>
          <div className="lg:flex xs:hidden normal wrapper justify-center my-[70px] translate-x-[-225px]	ease-in duration-500">
            <HTMLFlipBook
              width={450}
              height={650}
              showCover={true}
              minWidth={315}
              maxWidth={1000}
              minHeight={400}
              maxHeight={1533}
              maxShadowOpacity={0.5}
              mobileScrollSupport={true}
              onFlip={handlePageChange}
              ref={flipBookRef}
              showPageCorners={true}
              usePortrait={false}
              flippingTime={1000}
              drawShadow={true}
              onInit={onInit}
              // useMouseEvents={false}
              onChangeState={(e) => {
                const wrapper = document.querySelector(".wrapper");
                const stfParent = document.querySelector(
                  ".normal .stf__parent"
                );
                if (wrapper && stfParent) {
                  wrapper.classList.add("pointer-events-none");
                  stfParent.classList.add("pointer-events-none");
                  setTimeout(() => {
                    wrapper.classList.remove("pointer-events-none");
                    stfParent.classList.remove("pointer-events-none");
                  }, 1000);
                  if (
                    (pagNum == 0 || pagNum == 3) &&
                    (e.data === "flipping" || e.data === "user_fold")
                  ) {
                    wrapper.classList.remove("translate-x-[-225px]");
                    if (pagNum == 3) {
                      wrapper.classList.remove("translate-x-[225px]");
                      wrapper.classList.add("translate-x-[0px]");
                    }
                    setCopyPagNum(pagNum);
                    setTimeout(() => {
                      stfParent.style.display = "none";
                    }, 1000);
                    setTimeout(() => {
                      document.querySelector(".theForm").style.display = "flex";
                    }, 1000);
                    setPagNum(1);
                  }
                }
              }}
            >
              {pagesArray.map((page, index) => (
                <div
                  key={index}
                  className={`${page.padding} h-full bg-primary rounded shadow-2xl shadow-inner`}
                >
                  <div
                    className={`demoPage h-full ${
                      page.withBorder ? "border-l-[.5px] border-primary" : ""
                    } `}
                    style={{
                      backgroundColor: page.bg,
                      boxShadow: page.shadow,
                      borderColor: "rgb(165 ,165, 165)",
                    }}
                  >
                    {page.withForm ? (
                      <div
                        className="w-full h-full  border-l-[.5px]  page-custom flex justify-center items-center"
                        style={{
                          background: "#fff8ee",
                          boxShadow:
                            "5px 5px 10px rgba(0, 0, 0, 0.4),inset 5px 1px 30px rgba(0, 0, 0, 0.4)",
                          borderColor: "rgb(165 ,165, 165)",
                        }}
                      >
                        <form
                          onSubmit={onSubmit}
                          className="w-full ease-in duration-200 px-[70px]"
                        >
                          <div className="w-full relative ease-in duration-200">
                            <input
                              id="bigName"
                              required
                              name="name"
                              type="text"
                              className="w-full px-4 border border-primary h-[42px] outline-none text-primary text-sm bg-transparent"
                              placeholder="NAME"
                              onChange={onNameChange}
                              value={name}
                            />
                          </div>

                          <div className="w-full relative ease-in duration-200 mt-5">
                            <textarea
                              id="bigMessage"
                              required
                              name="message"
                              type="text"
                              className="w-full px-4 border border-primary  outline-none text-primary text-sm py-3 bg-transparent"
                              placeholder="WRITE YOUR MESSAGE HERE"
                              onChange={onMessageChange}
                              rows="5"
                              value={Message}
                            ></textarea>
                          </div>

                          <button
                            type="submit"
                            className="mt-4 w-full border border-primary h-[42px] flex items-center justify-center text-center hover:bg-primary/20 ease-in duration-200"
                          >
                            <img src={arrow} alt="arrow" />
                          </button>
                        </form>
                      </div>
                    ) : (
                      ""
                    )}
                    {page.withContent ? (
                      <div
                        className="w-full h-full page-custom flex flex-wrap justify-center items-center"
                        style={{
                          background: "#fff8ee",
                          boxShadow:
                            "5px 5px 10px rgba(0, 0, 0, 0.4),inset 3px 2px 10px 0px rgba(0, 0, 0, 0.4)",
                        }}
                      >
                        <div className="px-[90px]">
                          <div className="w-full ">
                            <img
                              className="w-[130px]"
                              src={bookImage}
                              alt="book"
                            />
                          </div>
                          <div className="uppercase text-primary mt-6 text-sm leading-6">
                            “Share your love <br /> and joy to be part of <br />{" "}
                            their special day.”
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {page.withCoverWord ? (
                      <div className="flex justify-center items-center text-white  h-full">
                        <div className="uppercase text-center">
                          {sendedMessage}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {page.withEnd ? (
                      <div className="flex justify-center items-center text-white  h-full">
                        <div className="uppercase text-center">
                          {sendedMessage}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ))}
            </HTMLFlipBook>

            <div
              className=" w-[900px] h-[650px] relative theForm rounded-lg shadow-2xl"
              style={{ display: "none" }}
              onClick={(e) => {}}
            >
              <div className="md:w-6/12 h-full rounded p-[5px] pr-0 bg-primary ">
                <div
                  className="w-full h-full page-custom flex flex-wrap justify-center  items-center"
                  style={{
                    background: "#fff8ee",
                    boxShadow:
                      "5px 5px 10px rgba(0, 0, 0, 0.4),inset 3px 2px 10px 0px rgba(0, 0, 0, 0.4)",
                  }}
                >
                  <div className="px-[90px]">
                    <div className="w-full ">
                      <img className="w-[130px]" src={bookImage} alt="book" />
                    </div>
                    <div className="uppercase text-primary mt-6 text-sm leading-6">
                      “Share your love <br /> and joy to be part of <br /> our
                      special day.”
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:w-6/12 h-full rounded p-[5px] pl-0 bg-primary ">
                <div
                  className="w-full h-full  border-l-[.5px]  page-custom flex justify-center items-center"
                  style={{
                    background: "#fff8ee",
                    boxShadow:
                      "5px 5px 10px rgba(0, 0, 0, 0.4),inset 5px 1px 30px rgba(0, 0, 0, 0.4)",
                    borderColor: "rgb(165 ,165, 165)",
                  }}
                >
                  <form
                    onSubmit={onSubmit}
                    className="w-full ease-in duration-200 px-[70px]"
                  >
                    <div className="w-full relative ease-in duration-200">
                      <input
                        id="bigName"
                        required
                        name="name"
                        type="text"
                        className="w-full px-4 border border-primary h-[42px] outline-none text-primary text-sm bg-transparent"
                        placeholder="NAME"
                        onChange={onNameChange}
                        value={name}
                      />
                    </div>

                    <div className="w-full relative ease-in duration-200 mt-5">
                      <textarea
                        id="bigMessage"
                        required
                        name="Message"
                        type="text"
                        className="w-full px-4 border border-primary  outline-none text-primary text-sm py-3 bg-transparent"
                        placeholder="WRITE YOUR MESSAGE HERE"
                        onChange={onMessageChange}
                        rows="5"
                        value={Message}
                      ></textarea>
                    </div>

                    <button
                      type="submit"
                      className="mt-4 w-full border border-primary h-[42px] flex items-center justify-center text-center hover:bg-primary/20 ease-in duration-200"
                    >
                      <img src={arrow} alt="arrow" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="my-20 small-wraper w-full lg:hidden xs:flex justify-center  translate-x-[-125px]	ease-in duration-500">
            <HTMLFlipBook
              width={250}
              height={350}
              showCover={true}
              minWidth={315}
              maxWidth={1000}
              minHeight={400}
              maxHeight={1533}
              maxShadowOpacity={0.5}
              mobileScrollSupport={true}
              onFlip={handlePageChangeSmall}
              ref={flipBookRefSmall}
              showPageCorners={true}
              usePortrait={false}
              flippingTime={1000}
              drawShadow={true}
              onInit={onInit}
              // useMouseEvents={false}
              onChangeState={(e) => {
                const wrapper = document.querySelector(".small-wraper");
                const stfParent = document.querySelector(
                  ".small-wraper .stf__parent"
                );
                if (wrapper && stfParent) {
                  wrapper.classList.add("pointer-events-none");
                  stfParent.classList.add("pointer-events-none");
                  setTimeout(() => {
                    wrapper.classList.remove("pointer-events-none");
                    stfParent.classList.remove("pointer-events-none");
                  }, 1000);
                  console.log(pagNum);

                  setCopyPagNum(pagNum);

                  if (pagNum === 0) {
                    setTimeout(() => {
                      stfParent.style.display = "none";
                    }, 1000);
                    setTimeout(() => {
                      document.querySelector(
                        ".small-wraper .theForm"
                      ).style.display = "flex";
                    }, 1000);
                  }

                  // if (
                  //   (pagNum == 0 || pagNum == 3) &&
                  //   (e.data === "flipping" || e.data === "user_fold")
                  // ) {
                  //   wrapper.classList.remove("translate-x-[-125px]");
                  //   if (pagNum == 3) {
                  //     wrapper.classList.remove("translate-x-[125px]");
                  //     wrapper.classList.add("translate-x-[0px]");
                  //   }
                  //   setCopyPagNum(pagNum);
                  //   setTimeout(() => {
                  //     stfParent.style.display = "none";
                  //   }, 1000);
                  //   setTimeout(() => {
                  //     document.querySelector(".theForm").style.display = "flex";
                  //   }, 1000);
                  //   // setPagNum(1);
                  // }
                }
              }}
            >
              {pagesArray.map((page, index) => (
                <div
                  key={index}
                  className={`${page.padding} h-full bg-primary rounded shadow-2xl shadow-inner`}
                >
                  <div
                    className={`demoPage h-full ${
                      page.withBorder ? "border-l-[.5px] border-primary" : ""
                    } `}
                    style={{
                      backgroundColor: page.bg,
                      boxShadow: page.shadow,
                      borderColor: "rgb(165 ,165, 165)",
                    }}
                  >
                    {page.withForm ? (
                      <div
                        className="w-full h-full  border-l-[.5px]  page-custom flex justify-center items-center"
                        style={{
                          background: "#fff8ee",
                          boxShadow:
                            "5px 5px 10px rgba(0, 0, 0, 0.4),inset 5px 1px 30px rgba(0, 0, 0, 0.4)",
                          borderColor: "rgb(165 ,165, 165)",
                        }}
                      >
                        <form
                          onSubmit={onSubmit}
                          className="w-full ease-in duration-200 px-[20px]"
                        >
                          <div className="w-full relative ease-in duration-200">
                            <input
                              required
                              name="name"
                              type="text"
                              className="w-full px-4 border border-primary h-[42px] outline-none text-primary text-sm bg-transparent"
                              placeholder="NAME"
                              onChange={onNameChange}
                              value={name}
                            />
                          </div>

                          <div className="w-full relative ease-in duration-200 mt-5">
                            <textarea
                              required
                              name="Message"
                              type="text"
                              className="w-full px-4 border border-primary  outline-none text-primary text-sm py-3 bg-transparent"
                              placeholder="WRITE YOUR MESSAGE HERE"
                              onChange={onMessageChange}
                              rows="5"
                              value={Message}
                            ></textarea>
                          </div>

                          <button
                            type="submit"
                            className="mt-4 w-full border border-primary h-[42px] flex items-center justify-center text-center hover:bg-primary/20 ease-in duration-200"
                          >
                            <img src={arrow} alt="arrow" />
                          </button>
                        </form>
                      </div>
                    ) : (
                      ""
                    )}
                    {page.withContent ? (
                      <div
                        className="w-full h-full page-custom flex flex-wrap  items-center"
                        style={{
                          background: "#fff8ee",
                          boxShadow:
                            "5px 5px 10px rgba(0, 0, 0, 0.4),inset 3px 2px 10px 0px rgba(0, 0, 0, 0.4)",
                        }}
                      >
                        <div className="px-[20px]"></div>
                      </div>
                    ) : (
                      ""
                    )}
                    {page.withCoverWord ? (
                      <div className="flex justify-center items-center text-white h-full">
                        <div className="uppercase text-center px-8">
                          {sendedMessage}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ))}
            </HTMLFlipBook>

            <div
              className=" max-w-[500px] min-w-[500px] h-[350px] relative theForm rounded-lg shadow-2xl"
              style={{ display: "none" }}
              onClick={(e) => {}}
            >
              <div className="xs:w-6/12 h-full rounded p-[5px] pr-0 bg-primary ">
                <div
                  className="w-full h-full page-custom flex flex-wrap  items-center"
                  style={{
                    background: "#fff8ee",
                    boxShadow:
                      "5px 5px 10px rgba(0, 0, 0, 0.4),inset 3px 2px 10px 0px rgba(0, 0, 0, 0.4)",
                  }}
                >
                  <div className="px-[30px]"></div>
                </div>
              </div>
              <div className="xs:w-6/12 h-full rounded p-[5px] pl-0 bg-primary ">
                <div
                  className="w-full h-full  border-l-[.5px]  page-custom flex justify-center items-center"
                  style={{
                    background: "#fff8ee",
                    boxShadow:
                      "5px 5px 10px rgba(0, 0, 0, 0.4),inset 5px 1px 30px rgba(0, 0, 0, 0.4)",
                    borderColor: "rgb(165 ,165, 165)",
                  }}
                >
                  <form
                    onSubmit={onSmallSubmit}
                    className="w-full ease-in duration-200 px-[20px]"
                  >
                    <div className="w-full relative ease-in duration-200">
                      <input
                        id="smallName"
                        required
                        name="password"
                        type="text"
                        className="w-full px-4 border border-primary h-[42px] outline-none text-primary text-sm bg-transparent"
                        placeholder="NAME"
                        onChange={onNameChange}
                      />
                    </div>

                    <div className="w-full relative ease-in duration-200 mt-5">
                      <textarea
                        id="smallMessage"
                        required
                        name="password"
                        type="text"
                        className="w-full px-4 border border-primary  outline-none text-primary text-sm py-3 bg-transparent"
                        placeholder="WRITE YOUR MESSAGE HERE"
                        onChange={onMessageChange}
                        rows="5"
                      ></textarea>
                    </div>

                    <button
                      type="submit"
                      className="mt-4 w-full border border-primary h-[42px] flex items-center justify-center text-center hover:bg-primary/20 ease-in duration-200"
                    >
                      <img src={arrow} alt="arrow" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };
  useEffect(() => {
    window.addEventListener("resize", (e) => {
      renderContent();
    });
  }, []);
  return (
    <div className="min-h-[656.08]">
      <div className="w-full flex flex-wrap justify-center items-center mt-10">
        <div className="w-full flex justify-center">
          <img className="w-[130px]" src={bookImage} alt="book" />
        </div>
        <div className="uppercase tracking-[10px]	text-primary font-bold mt-6">
          guest book
        </div>
      </div>
      {renderContent()}
      <SecondLine />
    </div>
  );
};

export default FlipBook;
